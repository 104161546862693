<template>
  <v-row>
    <v-col :cols="6">
      <v-card>
        <v-card-title>
          {{ $t('service_pin_code') }}
        </v-card-title>
        <v-card-text>
          <div v-if="!cashRegisters.length">{{ $t('no_one_terminal') }}</div>
          <div v-else v-for="(pinCode, index) in cashRegisters" :key="index">
            <div class="d-flex justify-space-between align-center">
              <div>{{ pinCode.name }}</div>
              <div class="text-right">{{ pinCode.show ? pinCode.service_pin_code : '******' }}</div>
              <v-btn large icon @click="pinCode.show = !pinCode.show">
                <v-icon>{{ pinCode.show ? 'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    showPin: false,
    cashRegisters: []
  }),
  computed: {
    ...mapGetters(['user'])
  },
  mounted () {
    this.cashRegisters = this.user.cash_registers.map(el => {
      el.show = false
      return el
    })
  }
}
</script>

<style scoped>

</style>
