import moment from 'moment'
import 'moment/locale/ru'
import 'moment-timezone'
import axios from 'axios'

import store from '@/store'
import settings from '@/config/settings'
import qs from 'qs'
import { i18n } from '@/i18n'

moment.locale(i18n.locale)

export class Order {
  constructor (data) {
    this.id = data.id
    this.street = data.Street
    this.house = data.HouseNumber
    this.apartment = data.HouseRoom
    this.entrance = data.entrance
    this.floor = data.floor
    this.door_code = data.doorphone
    this.additional_info = data.StaffNotes
    this.deliveryComment = data.deliveryComment
    this.datetime = data.OrderDate
    this.courier = data.courier
    this.courierID = data.courierID
    this.client_name = data.ClientName
    this.phone_client = data.Phone
    this.agregator = data.aggregator
    this.agregator_delivery = data.aggregator_delivery
    this.amount = data.OrderAmount
    this.subtotal = data.subtotal
    this.cash = data.cash
    this.change = data.change
    this.paid = data.paid
    this.revision = data.revision
    this.payment = data.Payment
    this.payment_name = data.payment_name
    this.status = data.Status
    this.soon = data.soon
    this.type = data.OrderType
    this.pending = false
    this.accepted = data.staff_confirm
    this.accepted_at = data.staff_confirm_time ? data.staff_confirm_time : ''
    this.completed = data.closed
    this.completed_at = ''
    this.payments = data.payments
    this.onlinePayment = data.onlinePayment
    this.kassa_cash = data.kassa_cash !== null ? data.kassa_cash : ''
    this.kassa_card = data.kassa_card !== null ? data.kassa_card : ''
    this.iikoOrderReason = ''
    this.iikoNumber = data.iiko_number
    this.courier_service_id = data.courier_service_id
    this.skip_confirmation = data.skip_confirmation
    this.yandex_delivery_settings = data.yandex_delivery_settings
    this.seller_id = data.Seller
    this.seller_name = data.seller_name
    this.payment_types = data.payment_types
    this.actual_utc_completion_time = data.actual_utc_completion_time
  }

  get address () {
    if (this.type === 'delivery') {
      return `${this.street},  ${this.house}`
    } else {
      if (this.aggregator_delivery) {
        return i18n.t('pickup') + ' <img src="' + this.agregator.icon + '"> ' + this.agregator.name
      } else {
        return i18n.t('pickup')
      }
    }
  }

  get moment () {
    return moment(this.datetime)
  }

  get time () {
    return moment(this.datetime).format('HH:mm')
  }

  get orderTime () {
    if (this.soon) {
      return i18n.t('table.for_nearest')
    }

    return this.time
  }

  get acceptedTime () {
    const orderTime = moment(this.accepted_at)
    return orderTime.format('HH:mm')
  }

  get completedTime () {
    const orderTime = moment(this.completed_at)
    return orderTime.format('HH:mm')
  }

  get date () {
    const orderTime = moment(this.datetime)
    return orderTime.format('DD.MM.YYYY')
  }

  get statusIcon () {
    if (!this.accepted && !this.completed) {
      return {
        component: 'waitingAcceptIcon',
        color: '#fff'
      }
    }

    let data = []
    switch (parseInt(this.status)) {
      case settings.ORDER_STATUS_WAITING:
        data = {
          component: 'waitingAcceptIcon',
          color: '#fff'
        }
        break
      case settings.ORDER_STATUS_TIME_TO_COOK:
        data = {
          component: 'waitingCookingIcon',
          color: '#000'
        }
        break
      case settings.ORDER_STATUS_COOKING:
        data = {
          component: 'cookingIcon',
          color: '#000'
        }
        break
      case settings.ORDER_STATUS_READY:
        data = {
          component: 'readyIcon',
          color: '#000'
        }
        break
      case settings.ORDER_STATUS_CLOSED:
        data = {
          component: 'closedIcon',
          color: '#000'
        }
        break
      case settings.ORDER_STATUS_CANCELED:
        data = {
          component: 'canceledIcon',
          color: '#000'
        }
        break
      case settings.ORDER_STATUS_DELIVERING:
        data = {
          component: 'deliveringIcon',
          color: '#000'
        }
        break
      case settings.ORDER_STATUS_WAITING_DELIVERY:
        data = {
          component: 'readyForDeliveryIcon',
          color: '#000'
        }
        break
      default:
        data = {
          component: 'waitingCookingIcon',
          color: '#000'
        }
        break
    }
    return data
  }

  get statusColor () {
    if (!this.accepted && !this.completed) {
      return 'deep-orange accent-2'
    }

    switch (parseInt(this.status)) {
      case (settings.ORDER_STATUS_TIME_TO_COOK):
        return 'light-blue lighten-3'

      case (settings.ORDER_STATUS_COOKING):
        return 'blue-grey lighten-5'

      case (settings.ORDER_STATUS_READY):
        return 'lime lighten-4'

      case (settings.ORDER_STATUS_WAITING_DELIVERY):
        return 'amber lighten-4'

      case (settings.ORDER_STATUS_CANCELED):
        return 'brown lighten-5'

      case (settings.ORDER_STATUS_DELIVERING):
        return 'indigo lighten-5'

      case (settings.ORDER_STATUS_CLOSED):
        return 'teal lighten-5'

      default:
        return 'amber lighten-4'
    }
  }

  get statusRowColor () {
    if (!this.accepted && !this.completed) {
      return 'red darken-3'
    }

    switch (parseInt(this.status)) {
      case (settings.ORDER_STATUS_TIME_TO_COOK):
        return 'light-blue accent-3'

      case (settings.ORDER_STATUS_COOKING):
        return 'blue-grey lighten-3'

      case (settings.ORDER_STATUS_READY):
        return 'lime lighten-1'

      case (settings.ORDER_STATUS_WAITING_DELIVERY):
        return 'amber lighten-2'

      case (settings.ORDER_STATUS_CANCELED):
        return 'brown lighten-2'

      case (settings.ORDER_STATUS_DELIVERING):
        return 'indigo lighten-4'

      case (settings.ORDER_STATUS_CLOSED):
        return 'teal lighten-4'

      default:
        return 'amber lighten-4'
    }
  }

  get isOrderNew () {
    return !!settings.ORDER_STATUSES_NEW.includes(this.status)
  }

  get isOrderReadyToCook () {
    return this.accepted && !this.completed && this.status < settings.ORDER_STATUS_COOKING
  }

  get isOrderCooking () {
    return this.accepted && !this.completed && this.status === settings.ORDER_STATUS_COOKING
  }

  get isOrderReady () {
    return this.accepted && !this.completed && this.status === settings.ORDER_STATUS_READY
  }

  get isOrderAccepted () {
    return this.accepted
  }

  get isSendIIKO () {
    if (store.state.user.sendIIKO) {
      return this.accepted
    }
    return false
  }

  get isOrderActive () {
    return settings.ORDER_STATUSES_ACTIVE.includes(this.status)
  }

  get isOrderAcceptedAndTacked () {
    return !!(this.isOrderAccepted && this.isOrderActive)
  }

  get isOrderComplete () {
    return settings.ORDER_STATUSES_COMPLETED.includes(this.status)
  }

  get statusName () {
    return store.getters.getOrderStatusName(this.status)
  }

  get isSelectCourier () {
    return store.state.user.isSelectCourier
  }

  get needDelivery () {
    return (this.type === 'delivery')
  }

  get timeleft () {
    const ordertime = moment(this.datetime)
    const now = moment()
    let result = `${ordertime.diff(now, 'seconds')} ${i18n.t('time.sec')}`

    if (ordertime.diff(now, 'seconds') < 0) {
      return 0
    }

    if (ordertime.diff(now, 'seconds') > 60) {
      result = `${ordertime.diff(now, 'minutes')} ${i18n.t('time.min')}`
    }

    if (ordertime.diff(now, 'minutes') > 60) {
      result = `${ordertime.diff(now, 'hours')} ${i18n.t('time.h')}`
    }

    return result
  }

  otherSeller (sellerId) {
    return sellerId !== this.seller_id
  }

  iikoOrders () {
    return new Promise((resolve, reject) => {
      axios.get(settings.API_HOST + '/v2/iiko/orders/' + store.state.user.id + '/' + this.id, {
        params: {
          user_id: store.state.user.id,
          token: store.state.user.token
        }
      }).then(response => {
        resolve(response)
      }).catch(error => {
        let message = i18n.t('errors.server_error')
        if (error.response) {
          if (error.response.status === 400) {
            message = error.response.data.message
          }
        }

        store.state.errors.push({
          message: message
        })
        reject(error)
      })
    })
  }

  courierList () {
    return axios({
      method: 'post',
      url: settings.API_HOST + '/kassa/courier/list/' + store.state.user.city_id,
      data: qs.stringify({
        user_id: store.state.user.id,
        token: store.state.user.token
      })
    }).then(response => {
      return response.data
    }).catch(error => {
      let message = this.$t('errors.server_error')

      if (error.response && error.response.status === 400) {
        message = error.response.data.message
      }

      store.dispatch('showSnackbar', message)
    })
  }

  paymentList (type = 'takeaway') {
    return axios.get(
      settings.API_HOST + '/kassa/v2/payment-type/list', {
        params: { type }
      }).then(response => {
      return response
    }).catch(error => {
      let message = this.$t('errors.server_error')

      if (error.response && error.response.status === 400) {
        message = error.response.data.message
      }

      store.dispatch('showSnackbar', message)
    })
  }

  changePayment (payments) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: settings.API_HOST + '/kassa/v2/payment-type/change',
        data: {
          payments,
          order_id: this.id
        }
      }).then(response => {
        this.iikoOrderReason = i18n.t('text.changing_payment')
        store.commit('updateOpenOrder', { fields: ['payment_types'], values: { payment_types: payments } })
        resolve(response)
      }).catch(error => {
        if (error.response.status === 400) {
          store.dispatch('showSnackbar', error.response.data.message)
        }
        reject(error)
      })
    })
  }

  changeCourier (courierId) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: settings.API_HOST + '/kassa/courier/change',
        data: {
          courierId: courierId,
          orderId: this.id
        }
      }).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changeStatus (status) {
    return axios({
      method: 'POST',
      url: settings.API_HOST + '/kassa/order/change_status',
      data: qs.stringify({
        order_id: this.id,
        status: status
      })
    })
  }

  getPayments () {
    return axios({
      method: 'POST',
      url: settings.API_HOST + '/kassa/order/payments',
      data: qs.stringify({
        order_id: this.id
      })
    })
  }

  print (messages = {}) {
    store.state.worker.postMessage({
      type: 'print',
      lang: i18n.locale,
      token: store.state.user.token,
      url: settings.API_HOST + '/kassa/print/getPrintDataXML',
      params: {
        shop_id: store.state.user.id,
        order_id: this.id
      },
      messages: messages
    })
  }
}

export default Order
