import { i18n } from '@/i18n'
import IIKO from '@/Models/IIKO/IIKO'
import IIKOErrors from '@/Models/IIKO/IIKOErrors'
import store from '@/store'

import { sendBackend } from '@/functions/webservices'

import * as Sentry from '@sentry/vue'

function getWithExpiry (orderId) {
  const storageKey = 'order_notifications'

  let notifications = JSON.parse(localStorage.getItem(storageKey))

  if (!notifications) {
    notifications = {}
    localStorage.setItem(storageKey, JSON.stringify(notifications))
  }

  const expiryTime = notifications[orderId]

  if (!expiryTime || new Date().getTime() > expiryTime) {
    delete notifications[orderId]
    localStorage.setItem(storageKey, JSON.stringify(notifications))
    return null
  }

  return expiryTime
}

export default class WebsocketHandling {
  constructor (response) {
    this.response = response
  }

  handle () {
    switch (this.response.data.event) {
      case 'sendIIKO':
        // eslint-disable-next-line
        Sentry.captureMessage(`centrifugo sendIIKO ${JSON.stringify(this.response.data)}`)
        // eslint-disable-next-line no-case-declarations
        var orderIdRegex = this.response.data.data.Text.match(/№'(.*?),/)
        var orderId = orderIdRegex ? Number(orderIdRegex[1].trim()) : null

        // Если в списке найдено уведомление, то ничего не делать дальше
        if (getWithExpiry(orderId)) {
          Sentry.captureMessage(`Заказ отправлен повторно в течение последних 15 минут. orderId: ${orderId}`)
          return
        }

        // eslint-disable-next-line no-case-declarations
        const data = {
          lang: store.getters.getLocale,
          type: this.response.data.event,
          url: this.response.data.url,
          method: this.response.data.method,
          params: this.response.data.data,
          response: this.response.data.response,
          token: store.state.user.token
        }

        if (data.response) {
          if (this.response.data.returnChannel !== undefined) {
            data.returnChannel = this.response.data.returnChannel
          }
        }

        if (data.method !== 'GET' && data.method !== 'POST') {
          if (data.returnChannel !== undefined) {
            sendBackend(data.returnChannel, store.state.user.id, {
              message: i18n.t('errors.invalid_request_method')
            }, store.state.user.token)
          }
          break
        }

        Sentry.captureMessage(`Отправка в воркер данных: ${JSON.stringify(data)}`)
        store.state.worker.postMessage(data)

        Sentry.captureMessage(`Ставим блокировку на новые события 15 минут. OrderId: ${orderId}`)
        var notifications = JSON.parse(localStorage.getItem('order_notifications'))
        notifications[orderId] = new Date().getTime() + 1000 * 60 * 15 // 15 минут
        localStorage.setItem('order_notifications', JSON.stringify(notifications))

        break
      case 'updateOrders':
        store.dispatch('refresh')
        break
      case 'iikoCheck':
        IIKO.sendToIIKO('get', '/check').then(response => {
          this.iikoHandling('success', 'iikoCheck', response.data)
        }).catch(error => {
          const errors = IIKOErrors.responseError(error)
          this.iikoHandling('error', 'iikoCheck', errors)
        })
        break
      case 'iikoPayments':
        IIKO.sendToIIKO('get', '/payments').then(response => {
          this.iikoHandling('success', 'iikoPayments', response.data)
        }).catch(error => {
          const errors = IIKOErrors.responseError(error)
          this.iikoHandling('error', 'iikoPayments', errors)
        })
        break
      case 'iikoSections':
        IIKO.sendToIIKO('get', '/getsections').then(response => {
          this.iikoHandling('success', 'iikoSections', response.data)
        }).catch(error => {
          const errors = IIKOErrors.responseError(error)
          this.iikoHandling('error', 'iikoSections', errors)
        })
        break
      case 'iikoPriceCategories':
        IIKO.sendToIIKO('get', '/getpricecategories').then(response => {
          this.iikoHandling('success', 'iikoPriceCategories', response.data)
        }).catch(error => {
          const errors = IIKOErrors.responseError(error)
          this.iikoHandling('error', 'iikoPriceCategories', errors)
        })
        break
      default:
        console.log('err', this.response.data)
        break
    }
  }

  iikoHandling (status, type, data) {
    sendBackend('office.user.' + this.response.data.userId, store.state.user.id, {
      status: status,
      type: 'iikoInfo',
      subType: type,
      token: this.response.data.token,
      data: data
    }, store.state.user.token)
  }
}
